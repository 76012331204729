import React from 'react'
import { themeGet, Box, BorderBox, Text } from '@primer/components'
import { Link } from 'gatsby'
import styled from 'styled-components'

const CardWrapper = styled(BorderBox)`
  transition: all 200ms ease-in-out;

  :hover {
    box-shadow: ${themeGet('shadows.extra-large')};
  }
`

const maxWidth = large =>
  large ? ['250px', '250px', '250px', '350px'] : ['250px', '280px', '250px']

const Card = ({ imageContent, title, subtitle, path, action, large }) => {
  return (
    <Link to={path}>
      <CardWrapper
        borderRadius={2}
        border={0}
        boxShadow={'large'}
        width={'87vw'}
        maxWidth={maxWidth(large)}
        overflow="hidden"
        bg="light"
        color="bodytext"
      >
        <Box overflow="hidden">{imageContent}</Box>

        <Box p={4} pt={3}>
          <Text
            as="h2"
            mt={0}
            mb={1}
            fontSize={4}
            fontWeight="semibold"
            lineHeight="1.8rem"
          >
            {title}
          </Text>

          <Text as="h3" m={0} mt={1} fontSize={1} fontWeight="light">
            {subtitle}
          </Text>
        </Box>

        {action && (
          <BorderBox border={0} borderRadius={0} borderTop={1} p={3} pl={4}>
            <Text fontSize={1} fontWeight="light">
              {action}&nbsp;&nbsp;→
            </Text>
          </BorderBox>
        )}
      </CardWrapper>
    </Link>
  )
}

export default Card
