import React from 'react'
import { Link as RouterLink } from 'gatsby'
import { themeGet, Text } from '@primer/components'
import styled from 'styled-components'

import theme from '../../config/theme'

const StyledRouterLink = styled(RouterLink)`
  position: relative;
  padding-bottom: 2px;
  color: ${themeGet('colors.bodytext')};

  :after {
    background-color: ${themeGet('colors.bodytext')};
    position: absolute;
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    width: 100%;
  }

  :hover {
    color: ${theme.colors.primary};

    :after {
      background-color: ${theme.colors.primary};
    }
  }
`

const DisplayLink = ({ to, children, ...rest }) => (
  <Text as={StyledRouterLink} to={to} fontWeight="bold" {...rest}>
    {children}
  </Text>
)

export default DisplayLink
