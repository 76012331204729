import React from 'react'
import { Box, Heading } from '@primer/components'

import PageSection from './PageSection'
import PageWrapper from './PageWrapper'

const IndexSection = ({ title, darkerBackground, children }) => (
  <PageSection py={[10, 10, 12]} bg={darkerBackground ? 'accent.0' : ''}>
    <PageWrapper mb={[0, 8]}>
      <Box as="header" mb={[6, 9]}>
        <Heading as="h3" fontSize={[5, 6]} textAlign="center" fontWeight="bold">
          {title}
        </Heading>
      </Box>

      {children}
    </PageWrapper>
  </PageSection>
)

export default IndexSection
