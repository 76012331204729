import React from 'react'

import PageSetup from '../components/layout/PageSetup'
import Hero from '../components/page/index/Hero'
import IndexFeaturedSong from '../components/page/index/IndexFeaturedSong'

const IndexPage = () => (
  <PageSetup siteTitle="Byte-sized digital media" px={0}>
    <Hero />
    <IndexFeaturedSong />
  </PageSetup>
)

export default IndexPage
