import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import {
  themeGet,
  Flex,
  Heading,
  Text,
  Relative,
  Absolute,
  Box,
} from '@primer/components'
import styled from 'styled-components'

import DisplayLink from '../../reusable/DisplayLink'

const Circle = styled(Box)`
  border-radius: 50%;
  background: linear-gradient(
    135deg,
    rgb(71, 76, 78) 0%,
    ${themeGet('colors.bodytext')} 66%
  );
`

const HeroDisplayLink = ({ to, children }) => (
  <DisplayLink to={to} fontSize={[3, 4]} mr={4} bg="light">
    {children}
  </DisplayLink>
)

const Hero = () => (
  <StaticQuery
    query={graphql`
      query HeroPageLinksQuery {
        allPagesYaml {
          nodes {
            name
            url
            hero
            order
            hide
          }
        }
      }
    `}
    render={data => {
      const sortedLinks = data.allPagesYaml.nodes
        .filter(node => node.hero)
        .filter(node => !node.hide)
        .sort((a, b) => a.order - b.order)

      return (
        <Relative height="90vh" width="100%">
          <Flex
            justifyContent="center"
            alignItems={['start', 'center']}
            style={{ height: '100%', overflow: 'hidden' }}
            mx="auto"
          >
            <Circle
              width={['75vw', '50vw', '50vw', '33vw']}
              height={['75vw', '50vw', '50vw', '33vw']}
              maxWidth={'600px'}
              maxHeight={'600px'}
              bg="bodytext"
              mt={[10, 0]}
            />
          </Flex>
          <Absolute left={0} bottom={0} ml={[4, 6]} mb={6}>
            <header>
              <Heading
                as="h1"
                mb={3}
                pr={3}
                fontSize={[6, 7]}
                fontWeight="bold"
                lineHeight="1"
                style={{ display: 'inline-block' }}
                bg="light"
              >
                Acknosyn
              </Heading>
              <Text
                as="h2"
                pr={2}
                m={0}
                fontSize={[4, 5]}
                lineHeight={1.25}
                fontWeight="light"
                bg="light"
              >
                Byte-sized digital media
              </Text>
            </header>
            <Flex mt={[5, 7]}>
              {sortedLinks.map((link, i) => (
                <HeroDisplayLink
                  to={link.url}
                  key={link.name + link.url + i}
                >
                  {link.name}
                </HeroDisplayLink>
              ))}
            </Flex>
          </Absolute>
        </Relative>
      )
    }}
  />
)

export default Hero
