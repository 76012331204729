import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import IndexSection from '../../layout/IndexSection'
import CovertArt from '../../reusable/CovertArt'
import Card from '../../reusable/Card'

const IndexFeaturedSong = () => (
  <StaticQuery
    query={graphql`
      query LatestReleaseSongQuery {
        allMusicYaml {
          nodes {
            title
            imgName
            release
            genre
            showOnLanding
            fields {
              slugPath
            }
          }
        }
      }
    `}
    render={data => {
      const song = data.allMusicYaml.nodes.find(song => song.showOnLanding)

      return (
        <IndexSection title="Featured music" darkerBackground>
          <Card
            imageContent={
              <CovertArt imgName={song.imgName} title={song.title} />
            }
            title={song.title}
            subtitle={song.genre}
            path={song.fields.slugPath}
            action="Listen"
            large
          />
        </IndexSection>
      )
    }}
  />
)

export default IndexFeaturedSong
